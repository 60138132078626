/*  default colors
    Here are the variables we include (note that the :root is required)
    that can be accessed anywhere this SCSS is loaded.
*/

:root {
  --primaryColor: #185c8e;
  --secondaryColor: #807a70;
  --customFontSize: 1rem;
  --primaryDarkColor: #47999a;
  --leftNavbarColor: #807a70;
  --topNavbarColor: #FFF;
  --datatableRowHeight: 1rem;
  --bs-popover-header-bg: #185c8e;
}


/*===== Colors =====*/
$primary-color: var(--primaryColor);
$primary-dark-color: color-mix(in srgb, $primary-color, #000 15%);
$left-navbar-color: var(--leftNavbarColor);
$top-navbar-color: var(--topNavbarColor);
$secondary-color: var(--secondaryColor);
$background-color: #56606a;
$input-background-color: #FFF;


/*===== Font and Typograhy =====*/
/*$body-font-family: ;*/

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

/*===== Margin and Padding =====*/
$navbar-nav-link-padding-x: 1rem;

$spacer: 1rem;

$margin-sm: $spacer * 0.5;
$margin-base: $spacer * 1;
$margin-lg: $spacer * 1.5;
$margin-xl: $spacer * 2;
$margin-xxl: $spacer * 2.5;

$padding-sm: $spacer * 0.5;
$padding-base: $spacer * 1;
$padding-lg: $spacer * 1.5;
$padding-xl: $spacer * 2;
$padding-xxl: $spacer * 2.5;
