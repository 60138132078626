@import "_variables.scss";
@import "alhDatatable.scss";
@import "beurk.scss";
@import "bootstrap/scss/bootstrap";
@import "@ng-select/ng-select/themes/default.theme.css";

/* You can add global styles to this file, and also import other style files */
.btn-primary {
  color: #fff;
  background-color: $secondary_color !important;
  border-color: $secondary_color !important;

  &:active {
    color: #fff;
    background-color: $secondary_color !important;
    border-color: $secondary_color !important;

  }

  &:visited {
    color: #fff;
    background-color: $secondary_color !important;
    border-color: $secondary_color !important;
  }

  &:hover {
    background-color: $primary_color !important;
    border-color: $primary_color !important;
  }

  &:focus {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }
}

.btn-outline-primary {
  color: $primary_color !important;
  border-color: $primary_color !important;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: $primary_color !important;
  border-color: $primary_color !important;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: $primary_color !important;
  background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: $primary_color !important;
  border-color: $primary_color !important;
}

.dropdown-item.active {
  background-color: $primary-color;
}

.icheck-primary > input:first-child:not(:checked):not(:disabled):hover + input[type=hidden] + label::before, .icheck-primary > input:first-child:not(:checked):not(:disabled):hover + label::before {
  border-color: $primary-color;
}

a {
  color: $primary-color;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: $primary-dark-color;
  }
}

.form-control {
  &:focus {
    border-color: $primary-color;
  }
}

a.active-icon {
  color: $primary-color !important;

  &:hover {
    color: $secondary-color !important;
  }
}

a.nav-link {
  color: $primary-color;

  &:hover {
    color: $primary-dark-color !important;
  }
}


// overwriting the css properties to our need about the accordion
.accordion {
  --#{$prefix}accordion-btn-icon: #{escape-svg($accordion-button-icon)}
}

.accordion-button {
  &:not(.collapsed) {
    color: $primary-color;
    background-color: transparent;

    &::after {
      background-image: var(--#{$prefix}accordion-btn-icon);
    }
  }

  &:focus {
    border-color: $primary-color !important;
    box-shadow: 0 0 0 0.15rem $primary-color !important;
  }
}

.accordion-item {
  color: $primary-color;
  border: 2px solid $primary-color;
}


//overitting color of input range
input[type=range] {
  height: 1px;
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none;
}

/*webkit*/
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1.5em;
  height: 1.5em;
  border-radius: 1em;
  background: $primary-color;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: calc(max((0.7em - 1px - 1px) * 0.5,0px) - 1.5em * 0.5);
}

input[type=range]::-webkit-slider-runnable-track {
  height: 0.7em;
  border: 1px solid $primary-dark-color;
  border-radius: 0.5em;
  background: $primary-color !important;
  box-shadow: none;
}

input[type=range]::-webkit-slider-thumb:hover {
  background: $primary-color;
}

input[type=range]::-webkit-slider-runnable-track {
  border-color: $primary-color;
}

input[type=range]::-webkit-slider-thumb:active {
  background: $primary-color;
}

input[type=range]:active::-webkit-slider-runnable-track {
  background: $primary-color;
  border-color: $primary-dark-color;
}

/*mozilla*/
input[type=range]::-moz-range-thumb {
  width: 1.5em;
  height: 1.5em;
  border-radius: 1em;
  background: $primary-color;
  border: none;
  box-shadow: 0 0 2px black;
}

input[type=range]::-moz-range-track {
  height: max(calc(0.7em - 1px - 1px),0px);
  border: 1px solid $secondary-color;
  border-radius: 0.5em;
  background: $primary-color !important;
  box-shadow: none;
}

input[type=range]::-moz-range-thumb:hover {
  background: $primary-color;
}

input[type=range]:hover::-moz-range-track {
  border-color: $secondary-color;
}

input[type=range]::-moz-range-thumb:active {
  background: $primary-color;
}

input[type=range]:active::-moz-range-track {
  background: $primary-color;
  border-color: $secondary-color;
}

/*ms*/
input[type=range]::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type=range]::-ms-thumb {
  width: 1.5em;
  height: 1.5em;
  border-radius: 1em;
  background: $primary-color;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
}

input[type=range]::-ms-track {
  height: 0.7em;
  border-radius: 0.5em;
  background: $primary-color !important;
  border: 1px solid $secondary-color;
  box-shadow: none;
  box-sizing: border-box;
}

input[type=range]::-ms-thumb:hover {
  background: $primary-color;
}

input[type=range]:hover::-ms-track {
  border-color: $secondary-color;
}

input[type=range]::-ms-thumb:active {
  background: $primary-color;
}

input[type=range]:active::-ms-track {
  background: $primary-color;
  border-color: $secondary-color;

}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.langSelector {
  width: 60px;
}

.mainColor {
  color: var(--primaryColor);
  font-size: var(--customFontSize);
}

.secondaryColor{
  color: var(--secondaryColor);
  font-size: var(--customFontSize);
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.badge-info {
  color: #fff;
  background-color: $primary-color;
}

.ng-select.auto-grow {
  display: flex;

  .ng-select-container {
    min-width: 100px;
    width: auto;
  }

  .ng-dropdown-panel {
    width: auto;
  }
}
